// p= paragraph, v= video, c= caption, h= header, i= image, s= strong, A= text before link, a= link
// links should be embedded in paragraph

export const BlogList = [
  {
    title: "The Ultimate Guide to Curating an Exhibition",
    type: "teams",
    date: "July 11th 2022",
    banner: "0-banner.JPG",
    content: [
      "p",
      "I don’t recall when it started, but to my knowledge, I’ve always had an obsession with museums and exhibitions. Whenever I visit someplace new, my first instinct has always been to visit the most acclaimed museum in the area and I have rarely been disappointed. One of my favorite museums is the Nazi Documentation Center in Nuremberg, Germany. The storyline was compelling, the visitation route was well-designed, and the message conveyed was extremely powerful. It was a surreal experience.",
      "p",
      "In my senior year in college, I joined TEDxNationalTaiwanUniversity as the Director of Planning. I wasn’t that into TED talks but one of the key responsibilities of the job caught my eye. The Director of Planning was in charge of curating the annual exhibition. As a museum geek, I just couldn’t pass up the opportunity to curate my own exhibition. So, here’s the story of how my team and I curated the best exhibition in organization history.",
      "h",
      "PREPARATIONS",
      "p",
      "I was appointed to the position in late August along with the directors of other departments. We were scheduled to interview prospective members to join the team in late September so that left us one month to prepare. The first thing I did was meet with my predecessor to get a broad picture of what I was about to undertake. She said that for the previous exhibition they set up a few posters in a 10-square meter space. She also mentioned that simply completing that small exhibition was exhausting and they nearly didn’t finish on time.",
      "p",
      "I didn’t believe a word she said.",
      "p",
      "We are often limited simply by our lack of ambition. After I related my predecessor’s comments to the other directors, I proclaimed indignantly that the exhibition I plan on curating would be at least ten times as ambitious as the previous one. During the same meeting, the directors voted to hold the exhibition in mid-December which will leave my future team only 2 months to prepare. Despite the impending challenges, within a week, I completed a barebones proposal for the basic structure of the exhibition and then promptly rented the largest exhibition space in our school (300-square meters).",
      "h",
      "ASSEMBLING THE TEAM AND BRAINSTORMING",
      "p",
      "Due to my ambition, the design department was added to the curating team to supply the necessary manpower required to pull off this difficult feat. My department was in charge of deciding the theme of the exhibition and subsequent content creation. The design department was in charge of designing and constructing the exhibits. Our two departments also assisted each other’s tasks regularly throughout the organizing stage.",
      "p",
      "My newly recruited department members went straight to work after joining the team in early October. The first item to be determined was the core storyline of the exhibition. After much deliberation, we decided to tell the story of a typical Taiwanese college student and explore the impacts he or she may face. We would first invite the visitor to reflect on shocks in their own lives before leading them on to explore regional and global paradigm shifts. The exhibition would end with a space intended for personal contemplation. The final version of the exhibition consisted of 6 areas.",
      "p",
      "We named the exhibition At the Day’s End, a poetic proclamation taken from one of Tagore’s books.",
      "h",
      "Description of the 6 Areas that was Released to the Public",
      "s",
      "1. The Swirl of Sorrow: ",
      "p",
      "In this area, we start with the audio exhibition, in which we use pure sounds to demonstrate peer pressure and appearance anxiety. Being hunted by the pandemic, we are lost, losing our lives as well, drowning in the swirl of sorrow. ",
      "s",
      "2. Yearnet: ",
      "p",
      "Here, we discuss the conflicts between echo chambers that are formed by the algorithm of the internet. The generation of Net that we yearned for is now tearing our society apart. ",
      "s",
      "3. Falling of the Stars: ",
      "p",
      "This area explores the concept of democratic recession which is triggered by the rise of populism and the conflicts in society during the pandemic. Sadly, some people now are suffering from the Falling of the Stars and the decaying of democracy. ",
      "s",
      "4. Scars on the Surface: ",
      "p",
      "This area reveals tragedies such as wars, human rights abuses, discrimination, and environmental disasters. All the sorrows left scars on the surface, waiting to be soothed.",
      "s",
      "5. Into the Spectrum: ",
      "p",
      "This topic conveys that everyone’s perspectives and opinions are as diverse as the colors exhibited on the light spectrum. Let us head into the spectrum, valuing all voices no matter political standing, race, or gender.",
      "s",
      "6. Walk Through the Shadow: ",
      "p",
      "Although the pandemic shocked all aspects of the world, we can still walk through the shadow together. Eventually, our missing lives could be retrieved.",
      "h",
      "DESIGNING AND CONSTRUCTING",
      "p",
      "After determining the structure of the exhibition, my team went to work on honing the details of each area. At the same time, the design department was hard at work designing various aspects of the exhibition.",
      "i",
      "0-1.png",
      "c",
      "The Main Visual of the Exhibition. Design: Coco Huang.",
      "p",
      "Due to the scale of the exhibition, there were tons of exhibits and props that we had to make by hand beforehand. I won’t go into great detail regarding the construction aspect of the exhibition. However, it should be noted that more than 2,000 manhours went into creating every physical detail of the exhibition. This number alone should signify the great effort our team put into the exhibition. At the end of this article, I will showcase some snapshots of the exhibition.",
      "h",
      "MARKETING",
      "p",
      "It would be a great shame if no one visited our exhibition. For reference, the previous exhibition attracted under 100 visitors in five days. As such, I put a lot of emphasis on marketing. My team members shot promo films, recorded documentaries, and hosted numerous physical promo events. We also put together a website (which is unfortunately offline) as well as a ticketing system.",
      "h",
      "PUTTING EVERYTHING TOGETHER",
      "p",
      "After two long months of preparation, we were finally ready to showcase the exhibition to the world.",
      "i",
      "0-2.jpg",
      "c",
      "Opening Scene of the Exhibition. Postcards of Long-lost Dreams",
      "i",
      "0-3.jpg",
      "c",
      "An Interaction Area Where Visitors Could Write Down Insecurities on Post-its",
      "i",
      "0-4.jpg",
      "c",
      "The Exhibit for Democratic Recession, My Favorite Area",
      "i",
      "0-5.jpg",
      "c",
      "An Area Signifying that the World and Ourselves will Ultimately Find a Better Path Forward",
      "p",
      "We held the exhibition for a total of 4 days. During that time, we attracted more than 500 visitors, a 400% increase from the previous year. More than 99% of our visitors have reported positive experiences during the exhibition.",
      "p",
      "This was one of the hardest and most fulfilling experiences in my life. The sheer amount of tasks I had to execute and manage during the two-month organizing period challenged me to my limit. However, given the chance, I’ll gladly do it again.",
      "h",
      "ACKNOWLEDGEMENTS",
      "p",
      "The exhibition wouldn’t have been possible without the help of my partner, Julie, and team members.",
      "i",
      "0-6.jpg",
      "c",
      "From left to right: (Design) Alice, Vera, Coco, Vivian, Julie [Front] , Nancy; (Planning) Ashley, Alice, Igor [Front], Jessica, Eileen, Jim",
      "z",
      "Follow ",
      "a",
      ["https://www.facebook.com/TEDxNTUtw", "TEDxNationalTaiwanUniversity "],
      "f",
      "on Facebook! ",
    ],
  },
  {
    title: "Directing and Editing without Experience",
    type: "skills",
    date: "July 13th 2022",
    banner: "1-banner.png",
    content: [
      "p",
      "People who know me well are all aware of one of my biggest passions in life, YouTube. I recently checked my phone usage stats and I’m quite embarrassed to reveal that I’ve logged an average of four hours per day on YouTube. In actuality, I don’t spend four hours per day watching memes or comedy videos. I usually spend a significant portion of time on YouTube watching the news, educational videos, or listening to music. Regardless of my excuse, it isn’t hard to infer that the media type of “video” is extremely appealing to me. However, for someone obsessed with video, it took me until a few months ago to try my hand at filming and editing a video on my own. Here’s how that went.",
      "h",
      "MOTIVATION",
      "p",
      "Last December, I was the curator of an ambitious exhibition. To ensure we had stellar visitor numbers, my team and I brainstormed extensively on what sort of marketing we could do to promote the exhibition. Creating a commercial was one of the most intuitive ideas we had.",
      "p",
      "The problem was, that no one in our team had any experience filming and editing videos, let alone a promo film. Since we had an extremely tight budget, that left us only one option if we wanted to produce a video for the exhibition. Someone had to learn how to film and edit from scratch. As you might have guessed, I volunteered. For some stupid reason, I thought I wasn’t busy enough at the time.",
      "h",
      "LEARNING THE BASICS",
      "p",
      "I spent one weekend learning the craft of filmmaking and postproduction. Let’s talk about the editing portion first. I’m quite a cheap person, as such, there was no way I was paying for Adobe Premiere. I also care way too much about copyright to install a bootleg version. After an hour of researching, I settled on using Da Vinci Resolve, a free professional-looking video editor even though online reviews all mention that it is more challenging to learn. ",
      "z",
      "To learn this supposedly challenging software, I watched a few tutorials. I found the one by Justin Brown (",
      "a",
      ["https://www.youtube.com/watch?v=63Ln33O4p4c", "link to the video"],
      "p",
      ") to be the most concise and well-taught by far. Before long, I was familiar with most of the basic functions of the editor and felt confident. One side note. To this day, I still use Da Vinci Resolve for all my video projects. It is an amazing editor and I don’t understand why anyone would pay for Adobe Premiere with this awesome free application out there. Highly recommend.",
      "p",
      "As for the filming side, I remember simply finding an article about filming with an iPhone and then going outside to practice. Unfortunately, I forgot which article I read. It should be easy to find good tutorials on the subject so I’ll leave that to you.",
      "h",
      "FILMING THE PROMO",
      "p",
      "Soon after I picked up the above theoretical knowledge, I discussed with my team to come up with ideas for the promo. In a nutshell, the exhibition was about the various impacts a typical college student would face and how he or she may try to make peace with them. We really wanted to showcase this core theme in the promo. In the end, our team came up with the idea of showing some of the key scenarios in the exhibition, alternating between characters to swiftly demonstrate the process one may go through when faced with challenging situations.",
      "p",
      "Due to our lack of experience, filming took a bit longer than expected. However, it was also quite fun (and addicting) to shout “action” like a real director. I got quite well at capturing different angles of the same scene which helped broaden my options when editing. Even though I kind of screwed up the exposure settings during filming, I did my best to adjust the footage via Da Vinci Resolve’s fantastic color editing feature. In my opinion, this promo wasn’t a bad first attempt at filmmaking. Please enjoy the final result.",
      "v",
      "https://www.youtube.com/embed/vi4KmVnspfw",
      "h",
      "GOING THE EXTRA MILE",
      "p",
      "Our team was quite pleased with the result of the promo. Since we had a little more spare time before the exhibition began, we began to brainstorm other ways we could utilize video in the exhibition. Someone suggested a behind-the-scenes short documentary to explain the thought process behind the curation of the exhibition. I liked the idea (partly due to me having the opportunity to say “action” again) and we got to work immediately, arranging interviews with key stakeholders of the exhibition. Even though the video is in Mandarin, there are English subtitles. Please enjoy the final result.",
      "v",
      "https://www.youtube.com/embed/KCkDiDhgj9k",
      "h",
      "FINAL THOUGHTS",
      "p",
      "Even though there were no flashy animations or transitions, I was still quite proud and happy I got the chance to learn how to edit and film videos. In the months to come, I have produced a few more videos and even learned how to do keyframe animations with Da Vinci Resolve (a story for another time). If you are even remotely interested in trying your hand at video production, I highly encourage you to just go ahead and start filming. The process is extremely labor-intensive, but my oh my is the result satisfying.",
      "h",
      "ACKNOWLEDGEMENTS",
      "f",
      "I would like to thank all members TEDxNationalTaiwanUniversity for the support and trust they have vested in me despite my high chance of failure at the time. I would especially like to thank Alice, Ashley, Eileen, Jim, Jessica, and Nicole for assisting with the script, filming, and editing. ",
    ],
  },
  {
    title: "The BackPacker Mindset",
    type: "travel",
    date: "July 17th 2022",
    banner: "2-banner.jpg",
    content: [
      "p",
      "I have enjoyed the thrill of backpacking travel for around four years since I did my first solo trip in 2018. Since the pandemic hit, my options became limited and I have not left Taiwan for over 2 years. During this time, I have seen numerous friends go on exchanges to North America and Europe (the only legitimate way of leaving the country for the past two years), envious of their freedom and experiences. ",
      "p",
      "However, I suspect that this feeling won’t last. For many, an exchange is their first and probably last chance to freely travel for an extended period due to prior and subsequent commitments. This is and will not be the case for me, which is why I wasn’t that motivated to apply for an exchange.",
      "p",
      "I noticed that while most of my friends all thoroughly enjoyed their journeys across these foreign lands, everyone’s motivation and takeaways seem to be different. In a way, most people’s exchanges could be described as their first “backpacking trip”, a journey where one may be left satisfied seeing a chunk of the world, whilst others may be left thirsty for more.",
      "p",
      "Surprisingly, I find that the vast majority of my peers fall into the former category. Being in that category doesn’t mean that the person won’t want to travel in the future, it’s just that a slew of other things such as career, family, education, relationships, etc. will always take precedent before travel. And while I have done significantly less traveling than many of my peers over the past 2 years, I would always firmly place myself in the latter category. This implies that when I consider major life choices, whether I would still possess the ability to travel plays a key role in my final decision.",
      "p",
      "I got curious whilst working on this website about what I was thinking when I embarked on and finished my first backpacking trip to Western Europe. I knew where to find the answer. I have written short posts on an Instagram account (@igor_backpacker) since the first trip and I recall that I have written material related to my motivation and takeaways. As I am writing this, I still haven’t read what I wrote over four years ago. What has led me to think the way I think today? What is my backpacker mindset? I won’t edit anything I wrote four years ago.",
      "h",
      "BEFORE THE TRIP",
      "p",
      "Today is the big day. The day I’ve been looking forward to for 2 years. As I watch Taiwan gradually shrinking, I finally realized that this is it. I am going on a backpacking trip to Europe. On my own. I keep asking myself why I want to do this so much. And I think there are three reasons. ",
      "p",
      "To begin with, I enjoy being challenged. After gaining admission to NTU in March, there was nothing to do except play and relax all day. I didn’t like that. I am very competitive and this lifestyle will make me soft. I desperately needed to think and solve problems. I believe this trip will offer lots of opportunities to do so. ",
      "p",
      "Next, Taiwan is not enough. As we globalize, it has become more and more important for us to learn about other cultures and values. There are 8 billion people in this world and only 23 million in Taiwan. I want to experience Europe and get to know the people there to broaden my horizon. ",
      "p",
      "Last but not least, there are lots of places I’ve always wanted to visit and revisit. Namely, the Louvre, Pompeii, nazi documentation, and my host family in Italy. Books and videos are not enough. Only when you visit in person will you be able to completely understand the place. ",
      "p",
      "To conclude, I will like to thank some people who made this trip possible. Firstly, my parents. Lest their financial support, this trip wouldn’t have been possible and I am extremely grateful to be born into such a wonderful family. Second, my friends in Italy - the Colombo family. Thank you, especially to Salvatore and Chiara for assisting me in booking and planning the Italian part of my trip. Also, thank you for allowing me to stay at your place in Milan. Thank you for reading this long and boring essay. I will post at least once per day to share my thoughts and tips during this epic trip to Europe.",
      "h",
      "AFTER THE TRIP",
      "s",
      "The backpacker spirit:",
      "p",
      "I’ve talked about my trip with many friends, sharing many interesting and funny stories. However, I’d scarcely told anyone how I felt during the trip and what I learned. One reason is that no one gives a damn, but it’s mostly because my biggest revelation came after my backpacking trip.",
      "p",
      "During my backpacking trip, I visited different destinations every single day. Every morning when I woke up, I was always super excited about the adventures to come. Too long had I studied the same boring subjects repeatedly during high school. Like a bird freed from its cage, I took every opportunity during my trip to experience everything.",
      "p",
      "I thought that I found the formula to true happiness - travel. Unfortunately, after I came back to Taiwan, I realized a fundamental flaw in my formula. It’s quite obvious. You can’t travel every day.",
      "p",
      "Henceforth began the first few months of college. I made some good friends, participated in some extracurricular activities, etc. But I just couldn’t stop thinking about how great I felt during my backpacking trip and that made me occasionally depressed. Little things often agitated me. An unread message, rain, a point lost from an unimportant quiz.",
      "p",
      "I started looking for flights, trying to escape the reality around me. It was then that I stumbled upon Kenji’s blog.",
      "p",
      "I started thinking about his enthusiasm, positivity, and energy. No matter what obstacles were in his way, he always laughed loudly and said it was fine. I realized that the answer was under my nose all along.",
      "p",
      "The reason why travel makes us so happy is that we get the opportunity to forget about the little things in life. If we make an effort to ditch negative thoughts and stop caring about the small, irritable issues in life, every day is like travel.",
      "p",
      "It’s hard to state what I truly learned in exact words and the above may seem like a pile of crap to some people but this is what I truly think.",
      "h",
      "CONCLUSION",
      "p",
      "That went a little differently than I thought it would. The motivation I had four years ago is consistent with what I think today. However, I was a bit surprised by my takeaway a few years back. It left me deep in thought for many minutes. It seems that as time passed by, I have forgotten one of the most important lessons I learned from traveling.",
      "s",
      "“If we make an effort to ditch negative thoughts and stop caring about the small, irritable issues in life, every day is like travel.” ",
      "p",
      "It is easy for me to say that I love traveling and would always make the ability to travel a priority. However, traveling is shouldn’t be the solution. When my friends got to travel, I got jealous in part due to my monotonous life. I planned to take a big trip after military service, framing it as a reward for my current suffering. Forgive me for reversing my opinion mid-article, but I now realize what I wrote in the first few paragraphs were quite short-sighted.",
      "p",
      "I love traveling. What I should’ve done and should do now is try to transform every day into a trip. That’s the true backpacker mindset. I’m glad I’ve found it once again.",
      "h",
      "ACKNOWLEDGEMENTS",
      "f",
      "Thanks to past me for the valuable lesson I re-learned whilst writing this article. ",
    ],
  },
];

// "p","", / "v","", / "c", "", / "h", "", / "i", "", / "s", "", / "z", "", / "a", ["",""],
